/** @format */

import React from "react";
import { Link } from "react-router-dom";

export default function Button({ text, link }) {
  return (
    <Link to={link}>
      <button
        type="button"
        className="inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
      >
        {text}
      </button>
    </Link>
  );
}
