/** @format */

import React, { useState, useRef, useEffect } from "react";

import axios from "axios";

export default function PayWithPayPal({
  totalPrice,
  nextStep,
  handleEmptyCart,
  orderData,
}) {
  const [error, setError] = useState(null);
  let paypalRef = useRef();

  // console.log(orderData);

  let items = orderData.items
    .map((item) => `Product Name: ${item.item.name} Quantity:${item.quantity}`)
    .join(" ");

  const submit = () => {
    axios.post("https://formsubmit.co/ajax/ts.now.me@gmail.com", {
      firstname: orderData.customer.firstname,
      lastname: orderData.customer.lastname,
      email: orderData.customer.email,
      street: orderData.shipping.street,
      city: orderData.shipping.town_city,
      state: orderData.shipping.county_state,
      zipcode: orderData.shipping.postal_zip_code,
      items: items,
      total: `$${totalPrice}`,
    });
  };

  useEffect(() => {
    if (totalPrice) {
      window.paypal
        .Buttons({
          createOrder: (data, actions) => {
            return actions.order.create({
              purchase_units: [
                {
                  description: "Strength Shop 509",
                  amount: {
                    currency_code: "USD",
                    value: totalPrice,
                  },
                },
              ],
            });
          },
          onApprove: async (data, actions) => {
            // const order = await actions.order.capture();
            submit();
            handleEmptyCart();
            nextStep();
            // console.log(order);
          },
          onError: (err) => {
            setError(err);
            console.error(err);
          },
        })
        .render(paypalRef.current);
    }
  });

  return (
    <div className="py-1">
      {error && <div>Uh oh, an error occurred! {error.message}</div>}
      <div ref={paypalRef} />
    </div>
  );
}
