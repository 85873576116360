/** @format */

import React from "react";
import {  Card, CardMedia } from "@material-ui/core";
import useStyles from "./styles";

const CartItem = ({
  item,
  handleUpdateCartQtyAdd,
  handleRemoveFromCart,
  handleUpdateCartQtySubtract,
}) => {
  const classes = useStyles();
  const itemId = item.item.id;
  let lessThanTwoItem = item.quantity < 2 ? true : false;

  return (
    <Card className="cart-item">
      <CardMedia
        image={`${
          item.item.product_image_urls.length > 0
            ? item.item.product_image_urls[0].url
            : "./imageSoon.png"
        }`}
        alt={item.item.name || "image coming soon"}
        className={classes.media}
      />

      <div className="text-3xl px-3 py-3">{item.item.name}</div>

      <div className=" flex justify-between">
        <div className={classes.buttons}>
          <button
            type="button"
            className="mx-3 inline-flex items-center px-2.5 py-.5 border border-transparent text-xs font-medium rounded text-red-700 bg-red-50 hover:bg-red-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
            disabled={lessThanTwoItem}
            onClick={() => handleUpdateCartQtySubtract(itemId)}
          >
            -
          </button>
          <div> &nbsp;{item.quantity}&nbsp; </div>
          
          <button
            type="button"
            className="mx-3 inline-flex items-center px-2.5 py-.5 border border-transparent text-xs font-medium rounded text-indigo-700 bg-indigo-50 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            onClick={() => handleUpdateCartQtyAdd(itemId)}
          >
            +
          </button>

        </div>
        <div className="px-3 py-3">
          <button
            type="button"
            className="inline-flex items-center px-5 py-2 border border-transparent text-base font-medium rounded-md text-red-700 bg-red-100 hover:bg-red-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
            onClick={() => handleRemoveFromCart(itemId)}
          >
            Remove
          </button>
        </div>
      </div>
    </Card>
  );
};

export default CartItem;
