/** @format */
import React, { useEffect, useState } from "react";
import "./App.css";
import { Route, Switch } from "react-router-dom";

import supabase from "./supabase";

import {
  Nav,
  About,
  Athlete,
  Contact,
  Footer,
  Products,
  Navbar,
  Cart,
  Checkout,
  useLocalStorage,
} from "./Components";



function App() {
  const [products, setProducts] = useState([]);

  let storageInitalVal;
  if (window.localStorage.getItem("cart") === null) {
    storageInitalVal = [];
  } else {
    storageInitalVal = window.localStorage.getItem("cart");
  }

  const [cart, setCart] = useLocalStorage("cart", storageInitalVal);

  const handleAddToCart = (productId, quantity) => {
    const item = products.find((product) => product.id === productId);

    if (!cart.find((product) => product.item.id === productId)) {
      const newCart = [...cart, { item, quantity }];
      setCart(newCart);
    } else {
      handleUpdateCartQtyAdd(item.id);
    }
  };

  const handleUpdateCartQtyAdd = (lineItemId) => {
    const newCart = cart.map((item) =>
      item.item.id === lineItemId
        ? { item: item.item, quantity: item.quantity + 1 }
        : item
    );

    setCart(newCart);
  };

  const handleUpdateCartQtySubtract = (lineItemId) => {
    const newCart = cart.map((item) =>
      item.item.id === lineItemId
        ? { item: item.item, quantity: item.quantity - 1 }
        : item
    );

    setCart(newCart);
  };

  const handleRemoveFromCart = (lineItemId) => {
    const newCart = cart.filter((item) => item.item.id !== lineItemId);
    setCart(newCart);
  };

  const handleEmptyCart = () => {
    setCart([]);
  };

  

  useEffect(() => {
    (async () => {
      const { data, error } = await supabase.from("products").select(`
              *,
              product_image_urls (
                  product_id, url
              )
          `);
      if (error !== null) {
        return;
      }
      setProducts(data);
    })();
  }, []);

  return (
    <div>
      <Switch>
        
        <Route path="/cart">
          <Navbar totalItems={cart.length} />
          <Cart
            cart={cart}
            handleUpdateCartQtySubtract={handleUpdateCartQtySubtract}
            handleUpdateCartQtyAdd={handleUpdateCartQtyAdd}
            handleRemoveFromCart={handleRemoveFromCart}
            handleEmptyCart={handleEmptyCart}
          />
        </Route>

        <Route path="/Shop">
          <Navbar totalItems={cart.length} />
          <Products products={products} onAddToCart={handleAddToCart} />
        </Route>

        <Route path="/Checkout">
          <Checkout cart={cart} handleEmptyCart={handleEmptyCart} />
        </Route>

        <Route path="/">
          <Nav />
          <div id="About">
            <About />
          </div>
          <div id="Kakande">
            <Athlete pictureRight={false} athleteName="Kakande Muzamiru" />
          </div>

          <div id="Contacts">
            <Contact />
          </div>
          <Footer />
        </Route>
      </Switch>
    </div>
  );
}

export default App;
