/** @format */

import React from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/swiper.min.css";
import "swiper/components/navigation/navigation.min.css";
import "swiper/components/pagination/pagination.min.css";

import "./swiperComponent.css";

// import Swiper core and required modules
import SwiperCore, {
  Navigation,
  Pagination,
  Mousewheel,
  Keyboard,
} from "swiper/core";

// install Swiper modules
SwiperCore.use([Navigation, Pagination, Mousewheel, Keyboard]);

export default function Slider() {
  return (
    <div>
      <Swiper
        cssMode={true}
        navigation={true}
        pagination={true}
        mousewheel={true}
        keyboard={true}
        className="mySwiper rounded-lg shadow-lg  "
      >
        <SwiperSlide>
          <img
            className="rounded-lg shadow-lg object-cover object-center"
            src="./k-images/k5.jpg"
            alt="Kakande Muzamiru"
          />
        </SwiperSlide>

        <SwiperSlide>
          <img
            className="rounded-lg shadow-lg object-cover object-center"
            src="./k-images/k3.jpg"
            alt="Kakande Muzamiru"
          />
        </SwiperSlide>

        <SwiperSlide>
          <img
            className="rounded-lg shadow-lg object-cover object-center"
            src="./k-images/k4.jpg"
            alt="Kakande Muzamiru"
          />
        </SwiperSlide>

        <SwiperSlide>
          <img
            className="rounded-lg shadow-lg object-cover object-center"
            src="./k-images/k2.jpg"
            alt="Kakande Muzamiru"
          />
        </SwiperSlide>
      </Swiper>
    </div>
  );
}
