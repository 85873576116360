/** @format */

import React, { useState} from "react";
import {
  Paper,
  Stepper,
  Step,
  StepLabel,
  Typography,
  
} from "@material-ui/core";


import AddressForm from "../AddressForm";
import PaymentForm from "../PaymentForm";
import OrderComplete from "../OrderComplete"
import useStyles from "./styles";

const steps = ["Shipping address", "Payment details"];
const Checkout = ({ cart,handleEmptyCart }) => {
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  const [shippingData, setShippingData] = useState({});

  const nextStep = () => setActiveStep((prevActiveStep) => prevActiveStep + 1);
  const backStep = () => setActiveStep((prevActiveStep) => prevActiveStep - 1);

  const test = (data) => {
    setShippingData(data);
    nextStep();
  };


  const Form = () =>
    activeStep === 0 ? (
      <AddressForm test={test} />
    ) : (
      <PaymentForm
        cart={cart}
        nextStep={nextStep}
        backStep={backStep}
        shippingData={shippingData}
        handleEmptyCart={handleEmptyCart}
      />
    );

  return (
    <>
      
      <div className={classes.toolbar} />
      <main className={classes.layout}>
        <Paper className={classes.paper}>
          <Typography variant="h4" align="center">
            Checkout
          </Typography>

          <Stepper activeStep={activeStep} className={classes.stepper}>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>

          {activeStep === steps.length ? <OrderComplete /> : <Form />}
        </Paper>
      </main>
    </>
  );
};

export default Checkout;
