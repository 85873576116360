/** @format */

import React, { useState, useEffect } from "react";
import { Container, Grid } from "@material-ui/core";
import { Link } from "react-router-dom";

import CartItem from "./CartItem/CartItem";


import { ArrowLeftIcon } from "@heroicons/react/solid";

const Cart = ({
  cart,
  handleUpdateCartQtyAdd,
  handleRemoveFromCart,
  handleEmptyCart,
  handleUpdateCartQtySubtract,
}) => {
  
  const [totalPrice, settotalPrice] = useState(0);

  const getTotal = () => {
    let total = 0;
    cart.forEach((product) => {
      let productPrice = product.item.price * product.quantity;
      total += productPrice;
    });
    settotalPrice(total);
  };

  useEffect(() => {
    getTotal();
  });

  const renderEmptyCart = () => (
    <Link  to="/Shop">
    <p className="inline-block">
        You have no items in your shopping cart ,&nbsp; start adding some 
        <ArrowLeftIcon  className="ml-3 -mr-1 h-5 w-5 inline text-blue-200 hover:text-blue-400" aria-hidden="true" /> 
      
      
    </p>
    </Link>
  );

  const renderCart = () => (
    <>
      <Grid container spacing={3}>
        {cart.map((lineItem) => (
          <Grid item xs={12} sm={6} md={4}  key={lineItem.item.id}>
            <CartItem
              item={lineItem}
              handleUpdateCartQtyAdd={handleUpdateCartQtyAdd}
              handleRemoveFromCart={handleRemoveFromCart}
              handleUpdateCartQtySubtract={handleUpdateCartQtySubtract}
            />
          </Grid>
        ))}
      </Grid>

      <div className="grid inline-grid l:grid-cols-2 my-8 w-full grid-cols-1 ">
        <div className="text-2xl ">Subtotal: ${totalPrice}</div>

        <div className="py-5">
          <button
            type="button"
            className="inline-flex items-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-red-700 bg-red-100 hover:bg-red-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
            onClick={() => handleEmptyCart()}
          >
            Empty cart
          </button>

          <Link to="/checkout" className="px-2">
            <button
              type="button"
              className="inline-flex items-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Checkout
            </button>
          </Link>
        </div>
      </div>
    </>
  );

  return (
    <Container>
      <div className="text-3xl py-6">Your Shopping Cart</div>
      {!cart.length ? renderEmptyCart() : renderCart()}
    </Container>
  );
};

export default Cart;
