/** @format */

import React from "react";
import ReactPlayer from "react-player";

export default function Youtube() {
  return (
    <div>
      <ReactPlayer
        controls
        width="auto"
        url="https://youtu.be/2tlNwtfi2KM"
      />
    </div>
  );
}
