/** @format */

import React, { useState } from "react";
import Input from "../Input";

import { ThumbUpIcon } from "@heroicons/react/solid";

import axios from "axios";

function ReceivedButton() {
  return (
    <button
      disabled={true}
      type="submit"
      className="mt-2 w-full inline-flex items-center justify-center px-6 py-3 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-blue-500 hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:w-auto"
    >
      
      Received &nbsp;
      <ThumbUpIcon className="mr-2 -mr-1 h-5 w-5 " aria-hidden="true" />
    </button>
  );
}

export default function Form() {
  const [nameValue, setNameValue] = useState("");
  const [emailValue, setEmailValue] = useState("");
  const [phoneValue, setPhoneValue] = useState("");
  const [subjectValue, setSubjectValue] = useState("");
  const [messageValue, setMessageValue] = useState("");
  const [submitted, setsubmitted] = useState(false);

  const valueClear = () => {
    setNameValue("");
    setEmailValue("");
    setPhoneValue("");
    setSubjectValue("");
    setMessageValue("");
  };
  const submit = (event) => {
    event.preventDefault();
    axios
      .post("https://formsubmit.co/ajax/ts.now.me@gmail.com", {
        name: nameValue,
        email: emailValue,
        phone: phoneValue,
        subject: subjectValue,
        message: messageValue,
      })
      .then(() => {
        valueClear();
        setsubmitted(true)
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div className="py-10 px-6 sm:px-10 lg:col-span-2 xl:p-12">
      <h3 className="text-lg font-medium text-warm-gray-900">
        Send us a message
      </h3>
      <form
        onSubmit={(e) => submit(e)}
        className="mt-6 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8"
      >
        
        <input type="hidden" name="_captcha" value="false"></input>
        <input
          type="hidden"
          name="_subject"
          value="Strength Shop 509 Contact Email"
        ></input>
        <div className="sm:col-span-2">
          <Input
            inputId="name"
            inputType="text"
            text="Name"
            autoComplete="name"
            value={nameValue}
            setValue={setNameValue}
            required={true}
          />
        </div>

        <Input
          inputId="email"
          inputType="email"
          text="Email"
          autoComplete="email"
          value={emailValue}
          setValue={setEmailValue}
          required={true}
        />

        <Input
          inputId="phone"
          inputType="tel"
          text="Phone"
          autoComplete="tel"
          optinalText="Optional"
          value={phoneValue}
          setValue={setPhoneValue}
        />

        <div className="sm:col-span-2">
          <Input
            inputId="subject"
            inputType="text"
            text="Subject"
            value={subjectValue}
            setValue={setSubjectValue}
            required={true}
          />
        </div>

        <div className="sm:col-span-2">
          <Input
            inputId="message"
            text="Message"
            isTextarea={true}
            optinalText="Max. 500 characters"
            value={messageValue}
            setValue={setMessageValue}
            required={true}
          />
        </div>

        <div className="sm:col-span-2 sm:flex sm:justify-end">
        {submitted ? <ReceivedButton />  :  <button
            type="submit"
            className="mt-2 w-full inline-flex items-center justify-center px-6 py-3 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-teal-500 hover:bg-teal-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-500 sm:w-auto"
          >
            Submit
          </button>   }
          

          
        </div>
      </form>
    </div>
  );
}
