/** @format */

import React, {useState, useEffect} from "react";
import Grid from "@material-ui/core/Grid";

import Product from "./Product/Product";
import useStyles from "./styles";
import ProductView from "./Product/ProductView"

const Products = ({ products, onAddToCart }) => {
  const classes = useStyles();

  const [open, setOpen] = useState(false)
  const [productId, setProductId]= useState("")
  const [productView, setProductView] = useState(products[0])

useEffect(()=> {
  setOpen(false)
},[])

  useEffect(()=>{
    const item = products.find((product) => product.id === productId);
    setProductView(item)
    
  },[products,productId])

  // const item = products.find((product) => product.id === productId);

  return ( <div>
        {productView && <ProductView open={open} setOpen={setOpen}  productId={productId} product={productView} onAddToCart={onAddToCart} />}
        
    <div className="py-5">
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <Grid container justify="center" spacing={4}>
          {products.map((product) => (
            <Grid key={product.id} item xs={12} sm={6} md={4} lg={3}>
              <Product product={product} onAddToCart={onAddToCart} setOpen={setOpen} setProductId={setProductId} />

            </Grid>
          ))}
        </Grid>
      </main>
    </div>
    </div>
  );
};

export default Products;
