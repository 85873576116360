/** @format */
import React from "react";
import { Link } from "react-router-dom";
import { Disclosure } from "@headlessui/react";

const navigation = [ 
  { name: "About", href: "#About" },
  { name: "Kakande Muzamiru", href: "#Kakande" },
  { name: "Contacts", href: "#Contacts" }, 
  { name: "Shop", href: "/Shop" },
];

export default function SmallNav() {
  return (
    <div>
      <Disclosure.Panel className="sm:hidden bg-gray-50">
        <div className="pt-2 pb-4 space-y-1">
          {navigation.map((item) =>
            item.name === "Shop" ? (
              <Link
                key={item.name}
                to={item.href}
                className="border-transparent text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700 block pl-3 pr-4 py-2 border-l-4 text-base font-medium"
              >
                <button
                  className="block w-full py-3 px-4 rounded-md shadow bg-indigo-500 text-white font-medium hover:bg-indigo-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-300 focus:ring-offset-gray-900"
                >
                  {item.name}
                </button>
              </Link>
            ) : (
              <Disclosure.Button className="border-gray-100 border-2 rounded-md text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700 block pl-3 pr-4 py-2 border-l-4 text-base font-medium w-full mt-1"
              key={item.name}
              >
              <a
                href={item.href}  
              >
                {item.name}
              </a>
              </Disclosure.Button>
            )
          )}
        </div>
      </Disclosure.Panel>
    </div>
  );
}
