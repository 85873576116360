/** @format */

import React, { useState, useEffect } from "react";
import { Typography, Divider } from "@material-ui/core";

import PayWithPayPal from "./PayWithPayPal";

import Review from "./Review";

function PaymentForm({ cart, nextStep, shippingData, handleEmptyCart }) {
  const [totalPrice, settotalPrice] = useState(0);

  const getTotal = () => {
    let total = 0;
    cart.forEach((product) => {
      let productPrice = product.item.price * product.quantity;
      total += productPrice;
    });
    settotalPrice(total);
  };

  useEffect(() => {
    getTotal();
  });

  const orderData = {
    items: cart,
    customer: {
      firstname: shippingData.firstName,
      lastname: shippingData.lastName,
      email: shippingData.email,
    },
    shipping: {
      street: shippingData.address1,
      town_city: shippingData.city,
      county_state: shippingData.shippingSubdivision,
      postal_zip_code: shippingData.zip,
    },}

  

  return (
    <div>
      <Review cart={cart} totalPrice={totalPrice} />
      <Divider />
      <Typography variant="h6" gutterBottom style={{ margin: "20px 0" }}>
        Payment method
      </Typography>

      <PayWithPayPal totalPrice={totalPrice} orderData={orderData} nextStep={nextStep} handleEmptyCart={handleEmptyCart} />
    </div>
  );
}

export default PaymentForm;
