/** @format */

import { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";

import { IconButton } from "@material-ui/core";

import { AddShoppingCart } from "@material-ui/icons";

import "./shop.css";

function DetailsThumb({ images, tab }) {
  return (
    <div className="thumb">
      {images.map((img, index) => (
        <img
          className="rounded opacity-70 block"
          src={img.url}
          alt="product"
          key={index}
          onClick={() => tab(index)}
        />
      ))}
    </div>
  );
}

export default function ProductView({ open, setOpen, product, onAddToCart }) {
  const [imageIndx, setImageIndx] = useState(0);

  const handleAddToCart = () => onAddToCart(product.id, 1);

  const onClick = (index) => {
    setImageIndx(index);
  };
  const isNoImage = product.product_image_urls.length > 0;
  const isMoreThanOneImage = product.product_image_urls.length > 1;

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        static
        className="fixed z-10 inset-0 overflow-y-auto"
        open={open}
        onClose={setOpen}
      >
        <div className="flex items-end justify-center min-h-screen  pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block   align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
              <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                <button
                  type="button"
                  className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  onClick={() => setOpen(false)}
                >
                  <span className="sr-only">Close</span>
                  <XIcon className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>

              <Dialog.Title
                as="h3"
                className="text-lg leading-6 font-medium text-gray-900  flex justify-between pt-5"
              >
                {product.name}
                {product.quantity > 0 ? (
                  <div className="tracking-wide px-1  ">${product.price}</div>
                ) : (
                  <div className="text-red-500  px-1 "> Sold out </div>
                )}
              </Dialog.Title>

              <button className="opacity-0 absolute bottom-0"></button>

              <div>
                <div className="details flex justify-around flex-wrap py-5">
                  <div className="big-img overflow-hidden shadow rounded-lg max-w-md m-6">
                    <img
                      className="w-full h-full block object-cover "
                      src={`${
                        isNoImage
                          ? product.product_image_urls[imageIndx].url
                          : "./imageSoon.png"
                      }`}
                      alt={product.name || "image coming soon"}
                    />
                  </div>

                  <div className="max-w-md m-6  ">
                    <p className="mt-3">{product.description}</p>

                    <div className="mt-6 flex-wrap overflow-auto">
                      {isMoreThanOneImage && (
                        <DetailsThumb
                          images={product.product_image_urls}
                          tab={onClick}
                        />
                      )}
                    </div>
                    <div className="mt-5  absolute  text-gray-600 right-2 py-3">
                      {product.quantity > 0 && (
                        <IconButton
                          aria-label="Add to Cart"
                          onClick={handleAddToCart}
                          disabled={product.quantity <= 0}
                        >
                          <AddShoppingCart />
                        </IconButton>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
