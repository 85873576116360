import React from 'react'
import {Link} from "react-router-dom"

export default function OrderComplete() {
    return (
        <div>
        <div className="py-3">
        Thank your for your order!
        </div>
            

            <Link to="/">
              <button
                type="button"
                className="inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-base font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                Back to the main page
              </button>
            </Link>
        </div>
    )
}
