import React from "react";
import { Link } from "react-router-dom";

const navigation = [
    { name: "Shop", href: "/Shop" },
    { name: "About", href: "#About" },
    { name: "Contacts", href: "#Contacts" },
  ];

export default function FullNav()
{
    return(
        <>
        {navigation.map((item) =>
            item.name === "Shop" ? (
              <Link
                key={item.name}
                to={item.href}
                className="border-transparent text-blue-700 hover:border-blue-300 hover:text-gray-700 inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium"
              >
                {item.name}
              </Link>
            ) : (
              <a
                key={item.name}
                href={item.href}
                className="border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700 inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium"
              >
                {item.name}
              </a>
            )
          )}
          </>
    )
}