/** @format */

import React from "react";
import {
  Card,
  CardMedia,
  CardContent,
  CardActions,
  Typography,
  IconButton,
} from "@material-ui/core";
import { AddShoppingCart } from "@material-ui/icons";
import { ZoomInIcon } from "@heroicons/react/solid";
import useStyles from "./styles";

const Product = ({ product, onAddToCart, setOpen, setProductId }) => {
  const classes = useStyles();

  const handleAddToCart = () => onAddToCart(product.id, 1);

  const handleProductView = () => {
    setOpen(true)
    setProductId(product.id)
  }
  return (
    <div>
      <Card className={classes.root}>
        <CardMedia
          className={classes.media}
          image={`${
            product.product_image_urls.length > 0
              ? product.product_image_urls[0].url
              : "./imageSoon.png"
          }`}
          title={product.name || "image coming soon"}
        />

        <CardContent>
          <div className={classes.cardContent}>
            <Typography gutterBottom variant="h5" component="h2">
              {product.name}
            </Typography>

            {product.quantity > 0 ? (
              <div className="tracking-wide">${product.price}</div>
            ) : (
              <div className="text-red-500"> Sold out </div>
            )}
          </div>
        </CardContent>

        <p className="p-4 product-description">{product.description}</p>

        <CardActions  className="flex justify-between">
            
          <button
            type="button"
            className="inline-flex items-center px-3 py-1.5 mb-2.5 border border-transparent shadow-sm text-base font-medium rounded-md text-white bg-yellow-400 hover:bg-yellow-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-400  view-button"
            onClick={() => handleProductView()}
          >
            View
            <ZoomInIcon className="ml-3 -mr-1 h-5 w-5" aria-hidden="true" />
          </button>
          {
            
            product.quantity > 0 &&
            <div className="view-cart">
             <IconButton
            aria-label="Add to Cart"
            onClick={handleAddToCart}
            disabled={product.quantity <= 0}
            
          >
            <AddShoppingCart  />
          </IconButton>
          </div>
          }
          
        </CardActions>
      </Card>
    </div>
  );
};

export default Product;
