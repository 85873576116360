/** @format */

import React from "react";
import { Disclosure } from "@headlessui/react";
import { IconButton, Badge } from "@material-ui/core";
import { ShoppingCart } from "@material-ui/icons";
import { Link, useLocation } from "react-router-dom";

import useStyles from "./styles";

export default function Navbar({ totalItems }) {
  const classes = useStyles();
  const location = useLocation();
  return (
    <Disclosure as="nav" className="bg-white shadow">
      <div className="flex-1 flex  items-stretch justify-start">
        <div className="flex-shrink-0 flex items-center py-4 px-3">
          <Link to="/">
          <img
            className="block h-8 w-auto px-2 "
            src="./boxing-glove.svg"
            alt="logo"
          />
          </Link>
        </div>
        <div className="absolute  text-gray-600 right-2 py-3">
          {location.pathname === "/Shop" && (
            <div className={classes.button}>
              <IconButton
                component={Link}
                to="/cart"
                aria-label="Show cart items"
                color="inherit"
              >
                <Badge badgeContent={totalItems} color="secondary">
                  <ShoppingCart />
                </Badge>
              </IconButton>
            </div>
          )}
        </div>
      </div>
    </Disclosure>
  );
}
