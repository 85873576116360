/** @format */

import React from "react";

export default function Input({
  inputId,
  inputType,
  text,
  autoComplete,
  optinalText,
  isTextarea = false,
  value,
  setValue,
  required = false,
  py = "3",
  px = "4",
}) {

  const valueChange = (func, event) => {
    func(event.target.value);
  };
  return (
    <div>
      <label
        htmlFor={inputId}
        className="block text-sm font-medium text-warm-gray-900"
      >
        <div className="flex justify-between">
          {text}{" "}
          <span className="text-sm text-warm-gray-500">{optinalText}</span>
        </div>
      </label>

      <div className="mt-1">
        {isTextarea ? (
          <textarea
            name={inputId}
            id={inputId}
            rows={4}
            value={value}
            onChange={(event)=>valueChange(setValue, event)}
            required={required}
            className="py-3 px-4 block w-full shadow-sm text-warm-gray-900 focus:ring-teal-500 focus:border-teal-500 border-warm-gray-300 rounded-md"
            aria-describedby="message-max"
            
          />
        ) : (
          <input
            type={inputType}
            name={inputId}
            id={inputId}
            value={value}
            onChange={(event)=>valueChange(setValue, event)}
            autoComplete={autoComplete}
            required={required}
            className={`py-${py} px-${px} block w-full shadow-sm text-warm-gray-900 focus:ring-teal-500 focus:border-teal-500 border-warm-gray-300 rounded-md`}
          />
        )}
      </div>
    </div>
  );
}

