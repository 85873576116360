import { createClient } from '@supabase/supabase-js';


const supabaseUrl = 'https://lqxvzmpvqoeprxbzygmd.supabase.co';


// the key in the .env is the anon user's key 



const supabase = createClient(supabaseUrl, process.env.REACT_APP_SUPABASE_KEY);

export default supabase; 




