/** @format */
import React from "react";
import Button from "./Button";

import Slider from "./Swiper/Swiper"

export default function Athlete({ pictureRight, athleteName }) {
  return (
    <div className="bg-warm-gray-50 overflow-hidden">
      <div className="relative max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:px-8">
        <div className="mx-auto text-base max-w-prose lg:grid lg:grid-cols-2 lg:gap-8 lg:max-w-none">
          <div>
            <h2 className="text-base text-indigo-600 font-semibold tracking-wide uppercase">
            Sponsored Athlete 
            </h2>
            <h4 className="mt-1 text-xl font-semibold text-gray-400"> Meet </h4>
            <h3 className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
               {athleteName}
            </h3>

          </div>
        </div>
        <div className="mt-8 lg:grid lg:grid-cols-2 lg:gap-8">
          <div
            className={`relative lg:row-start-1 ${
              pictureRight ? "lg:col-start-1 " : "lg:col-start-2"
            }`}
          >     
            <div className="relative text-base mx-auto max-w-prose lg:max-w-none">
              <figure>
                <div className="aspect-w-12 aspect-h-12 lg:aspect-none">
                  <Slider />
                </div>
              </figure>
            </div>
          </div>

          <div className="mt-5 lg:mt-0">
            <div className="text-base max-w-prose mx-auto lg:max-w-none">
              <p className="text-lg text-gray-500">
              The Real king Kongo <span className="font-semibold text-gray-700">"I DON'T STOP WHEN I'M TIRED I STOP WHEN I'M DONE"</span> .
              </p>
            </div>
            <div className="mt-5 prose prose-indigo text-gray-500 mx-auto lg:max-w-none lg:row-start-1 lg:col-start-1">
              <p>
              Muzamir Kakande, a 2017 Africa Boxing Championship gold medalist. The elusive hard-hitting puncher has achieved all that in less than 7 years. With a continental medal already in the bag, he eyes for more rings before he hangs his gloves.
              </p>
              <p>
              "Like any person who has a dream it was hurting for me to leave my family, a country I got used to, my friends, other boxers, my coaches. But because I have a dream to become the best. If I managed to conquer the continent, my dream is to conquer the World and become the best in my Weight and I know with the help of God and my new coaches I will make it and I will bring honor to my country and everyone that has helped. Wining the world title is my dream and like I shared before, I intend to retire soon."
              </p>
              
                <div className=" flex justify-center w-10/12">
                <Button text="Shop Now" link="/Shop" />
                </div>              
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}


