/** @format */
import React, { useState } from "react";
import { Disclosure } from "@headlessui/react";
import FullNav from "./FullNav";
import DropDownNav from "./DropDownNav";
import SmallNav from "./SmallNav";
import Burger from "./Burger";

const dropdownAthleat = [
  {
    name: "Kakande Muzamiru",
    href: "#Kakande",
    description: "Pro Boxing",
    icon: "./boxing-glove.svg",
  },
];

export default function Nav() {
  const [dropdown, setDropdown] = useState(false);

  const dropdownClickHandler = () => {
    if (dropdown) {
      setDropdown(false);
    } else {
      setDropdown(true);
    }
  };

  return (
    <Disclosure as="nav" className="bg-warm-gray-50 shadow">
      {({ open }) => (
        <>
          <div className="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8">
            <div className="relative flex justify-between h-16">
              <div className="absolute inset-y-0 right-0 flex items-center sm:hidden">
                {/* Mobile menu button */}
                <Burger open={open} />
              </div>
              <div className="flex-1 flex items-center justify-center sm:items-stretch sm:justify-start">
                <div className="flex-shrink-0 flex items-center">
                  <img
                    className="block h-8 w-auto"
                    src="./boxing-glove.svg"
                    alt="logo"
                  />
                </div>
                <div className="hidden sm:ml-6 sm:flex sm:space-x-8">
                  <FullNav />
                  <div className="border-transparent text-gray-500  hover:text-gray-700 inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium">
                    <DropDownNav
                      name="Athletes"
                      features={dropdownAthleat}
                      open={dropdown}
                      clickHandler={dropdownClickHandler}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <SmallNav />
        </>
      )}
    </Disclosure>
  );
}
